
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import View from '@/assets/svg/view.svg?inline'
import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import Search from '@/assets/svg/search.svg?inline'
import Picture from '@/assets/test/picture.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import Pagination from '@/components/molecules/Pagination.vue'

export default defineComponent({
  name: 'Students',
  data () {
    return {
      page: 1,
      studentsMeta: null,
      studentsData: {},
      errorstudents: null,
      isModalDelete: false,
      searchData: '',
      sortData: ''
    }
  },
  components: {
    AdminPanelNavMenu,
    View,
    Edit,
    Delete,
    Search,
    Picture,
    DefaultPopup,
    SortArrow,
    Pagination
  },
  watch: {
    searchData () {
      this.getStudents()
      this.page = 1
    }
  },
  mounted () {
    this.getStudents()
  },
  methods: {
    getStudents (params, sort) {
      let searchParam = `?page=${this.page}&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort
      this.$store.dispatch('students/getStudents', params).then(
        (res) => {
          this.studentsData = res.data
          this.studentsMeta = res.meta
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    viewStudent (id) {
      this.$router.push('/admin/student?student=' + id)
    },
    editstudent (id) {
      this.$router.push('/admin/edit-student?student=' + id)
    },
    openDeletePopup (id) {
      this.studentId = id
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
    },
    deleteStudent () {
      this.$store.dispatch('students/deleteStudent', this.studentId).then(
        (res) => {
          this.getStudents()
          this.isModalDelete = false
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getStudents()
    }
  }
})
