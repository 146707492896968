import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "students" }
const _hoisted_2 = { class: "students__block" }
const _hoisted_3 = { class: "students__search-wrap" }
const _hoisted_4 = { class: "students__search-wrap-mobile-container" }
const _hoisted_5 = { class: "students__search-wrap mobile-search" }
const _hoisted_6 = { class: "main-table__mobile-wrap" }
const _hoisted_7 = { class: "main-table__head" }
const _hoisted_8 = { class: "main-table__head-id" }
const _hoisted_9 = { class: "main-table__sort-block" }
const _hoisted_10 = { class: "main-table__head-big-tr" }
const _hoisted_11 = { class: "main-table__sort-block" }
const _hoisted_12 = { class: "main-table__head-big-tr" }
const _hoisted_13 = { class: "main-table__sort-block" }
const _hoisted_14 = { class: "main-table__head-big-tr" }
const _hoisted_15 = { class: "main-table__sort-block" }
const _hoisted_16 = { class: "main-table__body-id" }
const _hoisted_17 = { class: "main-table__body-little-tr" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "main-table__body-big-tr" }
const _hoisted_20 = { class: "main-table__body-big-tr" }
const _hoisted_21 = { class: "main-table__body-big-tr" }
const _hoisted_22 = { class: "main-table__body-big-tr" }
const _hoisted_23 = { class: "main-table__body-big-tr" }
const _hoisted_24 = { class: "students__popup-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_View = _resolveComponent("View")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[12] || (_cache[12] = _createTextVNode(" ID ")),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (this.getStudents('?search=', 'id')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (this.getStudents('?search=', '-id'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "main-table__head-little-tr" }, " Picture ", -1)),
        _createElementVNode("div", _hoisted_10, [
          _cache[13] || (_cache[13] = _createTextVNode(" Full Name ")),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getStudents('?search=', 'full_name')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getStudents('?search=', '-full_name'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[14] || (_cache[14] = _createTextVNode(" Email ")),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getStudents('?search=', 'email')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getStudents('?search=', '-email'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[15] || (_cache[15] = _createTextVNode(" Phone Number ")),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getStudents('?search=', 'phone')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getStudents('?search=', '-phone'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " Sport ", -1)),
        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " School ", -1))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentsData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "main-table__body",
          key: item
        }, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(item.id), 1),
          _createElementVNode("div", _hoisted_17, [
            (item.image === null)
              ? (_openBlock(), _createBlock(_component_Picture, { key: 0 }))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: item.image,
                  alt: "item.full_name"
                }, null, 8, _hoisted_18))
          ]),
          _createElementVNode("div", _hoisted_19, _toDisplayString(item.full_name), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(item.email), 1),
          _createElementVNode("div", _hoisted_21, _toDisplayString(item?.phone?.value), 1),
          _createElementVNode("div", _hoisted_22, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sports, (sport) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sport.id
              }, _toDisplayString(sport?.name?.title), 1))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_23, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.schools, (school) => {
              return (_openBlock(), _createElementBlock("div", {
                key: school.id
              }, _toDisplayString(school?.name?.title), 1))
            }), 128))
          ]),
          _createVNode(_component_View, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.viewStudent(item.id))
          }, null, 8, ["onClick"]),
          _createVNode(_component_Edit, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.editstudent(item.id))
          }, null, 8, ["onClick"]),
          _createVNode(_component_Delete, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.openDeletePopup(item.id))
          }, null, 8, ["onClick"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_Pagination, {
      propertyMeta: _ctx.studentsMeta,
      propertyList: _ctx.studentsData,
      page: _ctx.page,
      handlePage: _ctx.handlePage
    }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "students__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.deleteStudent && _ctx.deleteStudent(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "students__popup-button-no",
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}